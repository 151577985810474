import cx from 'classnames';
import { IParticipantPerson } from 'Interfaces/participantPerson';
import { observer } from 'mobx-react';
import React, { useState, useCallback, useRef, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { getElementAbsolutePosition } from 'Utils/domPositioning';
import { focusContentEditable } from 'Utils/inputNodeUtils';
import AtMentionHereItemObserver from '../AtMentionHereItem';
import AtMentionMemberItemObserver from '../AtMentionMemberItem';
import { AtMentionMembersProps } from './interfaces';

/**
 * List of `Participants` in a `Channel` who can be mentioned.
 *
 * **This named class is not decorated with `@observer`.** Use the default export from this module if you need the decorated class.
 *
 * @export
 */
const AtMentionMembers: React.FC<AtMentionMembersProps> = ({
  conversationId,
  loggedInPersonId,
  mentionListOpen,
  messageMentionFilter,
  onKeyDown,
  resolveConversationLinkPath,
  selectedMentionParticipantId,
  selectFilteredOtherParticipantPersonsInCurrentConversation,
  selectPersonPresenceStatus,
  selectUnreadCounts,
  setConversationAndTotalUnreadCount,
  updateMyLastReadMessage,
  className,
  insertMentionAtCursor,
  contentEditableRef,
  setMentionListOpen,
  onOpen,
  setSelectedMentionParticipantId,
}) => {
  const [openDownward, setOpenDownward] = useState(true);
  const menuRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<React.Component<DropdownProps>>(null);

  const onMentionItemClick = useCallback(
    (personIdStr: string) => {
      focusContentEditable(contentEditableRef.current, undefined, true);
      insertMentionAtCursor('@pr', `${personIdStr}`);
    },
    [contentEditableRef, insertMentionAtCursor]
  );

  const onHereClick = useCallback(
    (e: React.MouseEvent<Element>) => {
      e.preventDefault();
      insertMentionAtCursor('@', 'here');
    },
    [insertMentionAtCursor]
  );

  const toggleVisibility = useCallback(() => {
    setMentionListOpen(!mentionListOpen);
    onOpen && onOpen();

    if (!mentionListOpen) {
      if (dropdownRef.current) {
        const dropdownElem = ReactDOM.findDOMNode(
          dropdownRef.current
        ) as HTMLDivElement;
        const absPos = getElementAbsolutePosition(dropdownElem);
        setOpenDownward(absPos.top < absPos.maxAncestorClientHeight / 2);
      }
      setSelectedMentionParticipantId('here');
    } else if (mentionListOpen) {
      setSelectedMentionParticipantId(null);
    }
  }, [
    mentionListOpen,
    onOpen,
    setMentionListOpen,
    setSelectedMentionParticipantId,
  ]);

  const isSelected = useCallback(
    (p: IParticipantPerson) => {
      return (
        (selectedMentionParticipantId === p.participant.id ||
          selectedMentionParticipantId ===
            p.participant.personId?.toString()) &&
        !!selectedMentionParticipantId
      );
    },
    [selectedMentionParticipantId]
  );

  const filteredOtherPtcPersons =
    selectFilteredOtherParticipantPersonsInCurrentConversation(
      messageMentionFilter
    ) as IParticipantPerson[];

  const mentionOptions = useMemo<IParticipantPerson[]>(
    () =>
      filteredOtherPtcPersons &&
      filteredOtherPtcPersons.length > 0 &&
      filteredOtherPtcPersons.filter((p) => p.person.id !== loggedInPersonId),
    [filteredOtherPtcPersons, loggedInPersonId]
  );

  return (
    <Dropdown
      className={cx('mention-dropdown', className)}
      icon="at"
      onClick={toggleVisibility}
      ref={dropdownRef}
      open={mentionListOpen}
      onKeyDown={onKeyDown}
    >
      <Dropdown.Menu
        className={cx('mention-dropdown-menu', {
          'mention-dropdown-menu-flipped': openDownward,
        })}
      >
        {openDownward && <div className="top-triangle" />}
        <div className="mention-members-list menu scrolling" ref={menuRef}>
          <AtMentionHereItemObserver
            key="here"
            isSelected={selectedMentionParticipantId === 'here'}
            conversationId={conversationId}
            resolveConversationLinkPath={resolveConversationLinkPath}
            onHereClick={onHereClick}
          />
          {mentionOptions.map((p) => (
            <AtMentionMemberItemObserver
              conversationId={conversationId}
              isSelected={isSelected(p)}
              key={p.participant.id}
              onMentionClick={onMentionItemClick}
              participantPerson={p}
              resolveConversationLinkPath={resolveConversationLinkPath}
              selectPersonPresenceStatus={selectPersonPresenceStatus}
              selectUnreadCounts={selectUnreadCounts}
              setConversationAndTotalUnreadCount={
                setConversationAndTotalUnreadCount
              }
              updateMyLastReadMessage={updateMyLastReadMessage}
            />
          ))}
        </div>
        {!openDownward && <div className="bottom-triangle" />}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default observer(AtMentionMembers);
