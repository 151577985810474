import contactImage from 'Assets/images/contacts.svg';
import { FeatureFlag } from 'Components/shared/FeatureFlag';
import { NYLAS_V3 } from 'Constants/featureFlags';
import {
  STORE_CONTACT,
  STORE_CONVERSATION,
  STORE_NOTIFICATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_UI,
} from 'Constants/stores';
import { ContextPanelProps } from 'Containers/ContextPanel/interfaces';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import GoogleAuthLogin from '../GoogleAuthLogin';
import Microsoft from '../Microsoft';
import MicrosoftBusiness from '../MicrosoftBusiness';
import MicrosoftPersonal from '../MicrosoftPersonal';

class ContactsAddressBook extends React.Component<
  ContextPanelProps,
  { componentForRender: any }
> {
  constructor(props) {
    super(props);
    this.state = {
      componentForRender: null,
    };
  }

  render() {
    const { person, notification } = this.props;
    return (
      <div className="contacts">
        {
          <>
            <img className="main-image" src={contactImage} />
            <Button
              as={Link}
              to={{
                pathname: '/addressBook/contact',
                state: { from: '/contacts' },
              }}
              id="add-contact"
              content="+ ADD NEW CONTACT"
              className="flex-grow-shrink"
            />
            <div className="sync-contacts">
              <div className="sync-from">Synchronize contacts from:</div>
              <div className="source-container">
                <GoogleAuthLogin
                  person={person}
                  hideBelowText={true}
                  for="contacts"
                />

                <FeatureFlag
                  flag={NYLAS_V3}
                  fallback={
                    <>
                      <MicrosoftBusiness
                        person={person}
                        hideBelowText={true}
                        for="contacts"
                      />

                      <MicrosoftPersonal
                        person={person}
                        notification={notification}
                        hideBelowText={true}
                        for="contacts"
                      />
                    </>
                  }
                >
                  <Microsoft
                    person={person}
                    hideBelowText={true}
                    for="contacts"
                  />
                </FeatureFlag>
              </div>
            </div>
            <div className="contacts-footer">
              Your contacts will be synchronized between b-hive and your
              external account. Contacts will be visible only to you. Any
              changes in the b-hive will be propagated to your external source
              and vice versa.
            </div>
          </>
        }
      </div>
    );
  }
}
export default inject(
  STORE_PERSON,
  STORE_CONVERSATION,
  STORE_UI,
  STORE_PARTICIPANT,
  STORE_CONTACT,
  STORE_PHONE_CALL,
  STORE_NOTIFICATION
)(observer(ContactsAddressBook));
